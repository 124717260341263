import React, { useEffect, useState } from 'react';
// import { removeUserToken } from '../_helper/secureToken';
import { getCallback, removeUserToken, logoutHandler } from 'auth-component'
import { Navigate, useNavigate } from 'react-router-dom';

function Logout(props) {
    let navigate=useNavigate()
    useEffect(function () {
        navigate("/login")
    }, [])
    logoutHandler();
    // const [callbackStatus, setCallbackStatus] = useState(getCallback());
    // let REACT_APP_AUTHENTICATION_SERVER = process.env.REACT_APP_AUTHENTICATION_SERVER;
    // let REACT_APP_AUTH_PROVIDER_MODE = process.env.REACT_APP_AUTH_PROVIDER_MODE;

    // if(!REACT_APP_AUTH_PROVIDER_MODE){
    //     console.error("AUTH_PROVIDER_MODE Not Found : REACT_APP_AUTH_PROVIDER_MODE : ",REACT_APP_AUTH_PROVIDER_MODE)
    // }
    // if(REACT_APP_AUTH_PROVIDER_MODE=='INTERNAL'){
    //     // Work For Authentication in AuthServer 
    //     removeUserToken();
    //     if(callbackStatus){
    //         let callbackUrl = "/login?callback=" + callbackStatus
    //         // return "hi if if ";
    //         window.location.assign(callbackUrl) 
    //     }else{
    //         let callbackUrl = "/login";
    //         console.debug("If else : ");
    //         // return <Redirect to={{ pathname: callbackUrl }} />;
    //         return <Navigate to={{ pathname: callbackUrl }} />;
    //     }
    // }else{
    //     if(!REACT_APP_AUTHENTICATION_SERVER){
    //         console.error("AUTHENTICATION_SERVER Not Found : REACT_APP_AUTHENTICATION_SERVER : ",process.env.REACT_APP_AUTHENTICATION_SERVER)
    //     }
    //     console.debug("Else block : ");
    //     let logoutauthenticationUrl = `${REACT_APP_AUTHENTICATION_SERVER}/logout?callback=${window.location.href}`;
    //     removeUserToken()
    //     window.location.assign(logoutauthenticationUrl)

    // }
}

export default Logout;