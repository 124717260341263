import { publish } from './publisher.js';

const setterHelper = (model) => {
// console.log("permanentAddressHelper:setter",model)
  let self = {};
  self.set = (values, callback) => {
    Object.keys(values).map((v,i) => {
        model.model.map((m,mi) => {
          if(m.label == v){
              console.log("Jiren beating Hit", {m,mi,value: values[v],label: m.label, vlabel: v})
                model.model[mi].value = values[v];
            }
            m.version = m.version + 1;
        })
    })
    console.log("After Jiren Done", {model: model.model})
    callback();
    publish({data: model.model,id: model.id});
  }
  return self;
}

export default setterHelper;