import { configureStore } from '@reduxjs/toolkit';
import drawerReducer from './features/drawers/drawerSlice';
import dialogReducer from './features/dialogBox/dialogBoxSlice';
import confirmDialogReducer from './features/confirmDialogBox/confirmDialogBoxSlice';
import wheelSlice from './features/wheel/wheelSlice';
import uploadSlice from './features/uploadPic/uploadSlice';
import commonSlice from './features/commonDataSlice/commonSlice';
import sidebarSelectionSlice from './features/sidebarRouting/sidebarSelectionSlice';
import userInfo from './features/userInfoStore/userSlice';
import filesValidation from './features/filesValidation/filesValidation';
import snakebar from './features/snakebar/snakebar';
import toolsList from './features/toolsList/toolsList';

const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    confirmDialog: confirmDialogReducer,
    dialog: dialogReducer,
    wheel: wheelSlice,
    profile: commonSlice,
    imageCropper: uploadSlice,
    routing: sidebarSelectionSlice,
    user: userInfo,
    filesValidation: filesValidation,
    snakebar: snakebar,
    toolsList: toolsList
  },
});

export default store;
