
const validationHelper = () => {

  let self = {};


  self.required = () => { 
        return (value) => {
            console.log("testing", {value})
            if(Array.isArray(value) && value.length < 1){
                return {
                    error: true,
                    errorMessage: 'Field is required'
                }
            } else if(!value || value === null){
                return {
                    error: true,
                    errorMessage: 'Field is required'
                }
            }
            return {};
        }
    }

  self.pattern = (obj) => {
    return (value) => {
        let testValue = value;
        if(!Array.isArray(testValue)){
            testValue = [testValue];
        }
        let returnValue = {};
        testValue.map(v => {
            if(!(obj.regex).test(v)){
                returnValue = {
                    error: true,
                    errorMessage: obj.errorMessage
                }
            }
        })
        // console.log("inklab validation", {testValue,returnValue})
        return returnValue;
    }
  }

  // Length validator can have the following keys, min, max or absolute and with their respective definitions, it'll show the error message accordingly
  self.length = (obj) => { 
        return (value) => {
            if(obj.absolute !== undefined){
                if((value.length < (obj.absolute)) || (value.length > (obj.absolute))){
                return {
                    error: true,
                    errorMessage: `Field must only have ${obj.absolute} character(s)`
                }
            }
            }
            else if(obj.min === undefined){
                if((value.length > obj.max)){
                return{
                    error:true,
                    errorMessage: `Field can have at most ${obj.max} character(s)`
                }
            }
            }
            else if(obj.max === undefined){
                if(value.length < obj.min){
                return{
                    error:true,
                    errorMessage: `Field must have at least ${obj.min} character(s)`
                }
            }
            }
            else if((value.length < (obj.min)) || (value.length > (obj.max))){
                return {
                    error: true,
                    errorMessage: `Field must be in the range of ${obj.min} - ${obj.max} characters`
                }
            }
            return {};
        };
        
    }

  self.validation = ({validators}) => {
    if(!validators){
        return [];
    }
    let validator = validators.map(v => {
        switch(v.name){
            case 'required': return self.required();
            // case 'text': return self.text();
            case 'pattern': return self.pattern(v);
            // case 'email': return self.email();
            // case 'number': return self.number();
            // case 'pan': return self.pan();
            case 'length': return self.length(v);
            default: return false;
        }
    });

    return validator.filter(v => v);
  };
    
  return self;
}

export default validationHelper;