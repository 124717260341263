/* eslint-disable eqeqeq */
let subscribers = [];

function subscribe(fn, id) {
  subscribers.push({fn,id});
}

function publish({data,id}) {
  subscribers.filter(v => v.id == id).forEach((subscriber) => {
    subscriber.fn(data);
  });
} 

export { subscribe, publish };
