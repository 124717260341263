// -------Awards----------


export const personalDetailUrl = { list: "/", create: "/award/create" }

export const contactDetailUrl = { list: "/contact", create: "/user-award/create" }

export const officialDetailUrl = { list: "/official", create: "/accolades/create" }

export const familyDetailUrl = { list: "/family", create: "/promotion/create" }

export const educationUrl = { list: "/education", create: "/promotion/create" }

export const employementUrl = { list: "/employment", create: "/promotion/create" }

export const bankUrl = { list: "/bank", create: "/promotion/create" }

export const documentUrl = { list: "/document", create: "/promotion/create" }

export const passwordUrl = { list: "/password", create: "/promotion/create" }

export const ipAddressUrl = { list: "/ip-address", create: "/promotion/create" }

export const deviceSignInUrl = { list: "/device-signin", create: "/promotion/create" }






// -------Our People----------








// export const awardUrl={ list:"/award",create:"/award/create"}

