
import './App.css';
import './assets/styles/Customized.css';
import './assets/styles/Theme.css';
import { useEffect, useState } from 'react';
import { checkCallback, removeUserToken } from './_helper/secureToken';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PrivateComponent } from './components/PrivateComponent';
import Logout from './pages/Logout';
// import SSOLogin from './pages/Authentication/SsoLink';
import Register from './pages/Authentication/Register';
import ResetPassword from './pages/Authentication/ResetPassword';
import ForgotPassword from './pages/Authentication/forgotPassword';
import RegisterView from './pages/Authentication/RegisterView';
import LoginWrapper from './pages/Authentication/LoginWrapper';
import AuthenticateRender from './pages/Authentication/AuthenticateRender';
import EmailResetPassword from './pages/Authentication/EmailResetPassword';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function App() {
  const [component, setComponent] = useState([]);
  let navigate = useNavigate()
  useEffect(() => {
    setComponent(PrivateComponent());
  }, []);
  let query = useQuery();
  let authToken = query.get("token");
  let appToken = query.get("app");
  let authType = query.get("authType");
  let callback = query.get("callback");
  let ssoToken = query.get("ssoToken");
  let location = useLocation();
  let loginauthenticationUrl = `${process.env.REACT_APP_AUTHENTICATION_SERVER}/login?callback=${window.location.href}`;
  // let loginauthenticationUrl = "localhost:3000"
  // let loginauthenticationUrl = "https://www.google.com"
  return (  
    <Routes>
      {/* {component.map((u, i) =>
        checkCallback(authToken, appToken, authType, callback, ssoToken) ?
          <Route key={i} path={u.url} element={u.page} />
          :
          (window.location.assign(loginauthenticationUrl))
      )} */}

      {component.map((u, i) =>
        <Route key={i} exact={true} path={u.url}
          element={
            <AuthenticateRender>
            {u.page}
          </AuthenticateRender>
          }
        >
        </Route>
      )}

      <Route exact path="/login" element={<LoginWrapper/>}/> 
      {/* <Route path="/sso-link" element={<SSOLogin/>} /> */}
      <Route path="/register" element={<Register/>} />
      <Route path="/reset-password" element={<ResetPassword/>} />
      <Route exact path="/forgot-password" element={<ForgotPassword />}></Route>
      <Route exact path="/register-view" element={<RegisterView />} ></Route>
      <Route path="/logout" element={<Logout />} />
      <Route path="/password/reset/:token" element={<EmailResetPassword />} />
      {/* { url: '/password/reset/:token', page: <EmailResetPassword />, }, */}
      {/* { url: '/add-account', page: <LoginToAddNewAccount />, },
       { url: '/switch-account', page: <SwitchAccount />, }, */}

      {/* <Route path="/logout" render={() => {
            removeUserToken();
            if (callbackStatus) {
              let callbackUrl = "/login?callback=" + callbackStatus
              window.location.assign(callbackUrl)
              console.debug(callbackStatus, "location assing")
              return <Redirect to={{ pathname: callbackUrl }} />;
            } else {
              let callbackUrl = "/login";
              return <Redirect to={{ pathname: callbackUrl }} />;

            }
            removeUserToken();
            return <Redirect to={{ pathname: "/login" }} />;
          }}
          />
          <Route path="/logout-all-account" render={() => {
            removeUserToken();
            removeAllAccounts();
            if (callbackStatus) {
              let callbackUrl = "/login?callback=" + callbackStatus
              window.location.assign(callbackUrl)
              console.debug(callbackStatus, "location assing")
              return <Redirect to={{ pathname: callbackUrl }} />;
            } else {
              let callbackUrl = "/login";
              return <Redirect to={{ pathname: callbackUrl }} />;

            }
            removeUserToken();
            return <Redirect to={{ pathname: "/login" }} />;
          }}
          />
          <Redirect from="*" to="/" /> */}
    </Routes>
  );
}

export default App;