import { createSlice } from '@reduxjs/toolkit';

const filesValidation = createSlice({
    name: 'filesValidation',
    initialState: {
        fileExtensions: [],
        fileSize: [],
    },
    reducers: {
        setFileExtensions: (state, action) => {
            state.fileExtensions = action.payload.filter(v => v.activeStatus == '1').reduce((prev,curr) => {
                prev = [...prev,curr.extension,curr.mimeType]
                return prev;
              }, []);
        },
        setFileSize: (state, action) => {
            state.fileSize = parseInt(action.payload?.[0]?.size) || 25
        },
    },
});

export const { setFileExtensions,setFileSize } = filesValidation.actions;
export default filesValidation.reducer;
