import axios from "axios";
// import {captchaApi} from '../../_constants/api.constant'
// import { getAppToken, getSelfToken } from "../../_helper/secureToken";

export async function getCaptchaSetting() {
    let prarmTempObj = {};
    // prarmTempObj.app = getAppToken();
    // prarmTempObj.toolsUser = "null"
    const captchaApi = {CAPTCHASETTING: 'api/v2/captcha-setting'};

    console.log({url: process.env.REACT_APP_BASEURL + captchaApi.CAPTCHASETTING});
    const response = await axios.get(
        process.env.REACT_APP_BASEURL + captchaApi.CAPTCHASETTING,
        {
            params: prarmTempObj,
            headers: {
                // Authorization: 'Bearer ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        console.log({response})
        return response;
    }).catch(function (error) {
        console.log('prakhar');
        console.log(error.response);
        return error.response;
    });
    return response;
}