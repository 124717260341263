import { createSlice } from '@reduxjs/toolkit';

const toolsList = createSlice({
    name: 'toolsList',
    initialState: {
        tools: [],
        skills: [],
    },
    reducers: {
        setTools: (state, action) => {
            state.tools = action.payload.map(v => {
                return {
                    label: v.tool,
                    value: v.tool
                }
            });
        },
        setSkills: (state, action) => {
            state.skills = action.payload.map(v => {
                return {
                    label: v.skill,
                    value: v.skill
                }
            });
        },
    },
});

export const { setTools,setSkills } = toolsList.actions;
export default toolsList.reducer;
