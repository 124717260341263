
export default function Tabs(theme) {
    return {
      MuiTabs: {
        styleOverrides: {
          root: {
           
          }
        },
      },
    };
  }