import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import { closeDrawer } from './drawerSlice';
import { Box } from '@mui/material';

// const useStyles = makeStyles((theme) => ({
//   drawerPaper: {
//     width: '240px',
//   },
// }));

function SharedDrawer({ children, ...props }) {
  const dispatch = useDispatch();

  const id = props?.id;
  console.debug("SharedDrawer",id)

  const handleDrawerClose = () => {
    dispatch(closeDrawer(id));
  };

  // const isOpen = useSelector((state) => { return (state.drawer.id == id) ? state.drawer.isOpen : false });
  const isOpen = useSelector((state) => { return (state.drawer.id == id) ? state.drawer.isOpen : false });
  console.debug("useSelecctor", useSelector((state) =>state))




  // console.log({isOpen,children})
  return (
    <Drawer
      // sx={{ zIndex: 10000, }}
      anchor="right"
      open={isOpen}
      onClose={handleDrawerClose}
    //   classes={{ paper: classes.drawerPaper }}
    >
      <Box sx={{width:"45rem"}}>

        {children}
      </Box>
    </Drawer>
  );
}

export default SharedDrawer;
