import React from 'react';
import {
	Navigate,
} from "react-router-dom";
import { isSelfLogin,AuthConst } from 'auth-component';

const PrivateRoute = ({ children, loginStatus,loginPath, ...rest }) => {
	  
  const currentLoginPath=loginPath?loginPath:AuthConst.LOGIN_PATH;
  console.debug("PrivateRoute : isSelfLogin : ", isSelfLogin())

	return (isSelfLogin() ?
			<>
				{children}
			</>
			:
			<Navigate
				to={currentLoginPath}
			/>
	);
}
export default PrivateRoute;