import React from "react";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

function AppWrapper(props) {
    let currentEnv=process.env;
    console.debug("AppWrapper : Environment Object : ",currentEnv);
    return (
        <Router>
            <App />
        </Router>
    );
}


export default AppWrapper;
