import { createSlice } from '@reduxjs/toolkit';



const routingSlice = createSlice({
    name: 'routing',
    initialState: {
      currentRoute: window?.location?.pathname ?? '/',
    },
    reducers: {
      setCurrentRoute: (state, action) => {
        state.currentRoute = action.payload;
      },
    },
  });
  
  export const { setCurrentRoute,} = routingSlice.actions;
  export default routingSlice.reducer;