import { createSlice } from '@reduxjs/toolkit';

const snakebar = createSlice({
    name: 'snakebar',
    initialState: {
        snakebar: { dataLoad: false, message: "" },
    },
    reducers: {
        setSnackBar: (state, action) => {
            state.snakebar = action.payload;
        },
    },
});

export const { setSnackBar } = snakebar.actions;
export default snakebar.reducer;
