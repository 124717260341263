
import { createSlice } from '@reduxjs/toolkit';



const commonSlice = createSlice({
    name: 'profile',
    initialState: {
        open: false,
        image: null,
        coin:null,
        name:null,
    },
    reducers: {
      
        setProfileImage: (state, action) => {
            state.image = action.payload;
        },
        setCoinDetails: (state,action) =>{
            state.coin = action.payload;
        },
        setProfileName:(state,action) => {
            state.name = action.payload;
        },
    },
});

export const {setProfileImage,setCoinDetails,setProfileName } = commonSlice.actions;
export default commonSlice.reducer;
