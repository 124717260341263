import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { closeDialog } from './dialogBoxSlice';

function SharedDialog({children,...props}) {
//   const classes = useStyles();
  const dispatch = useDispatch();
  const id = props?.id;
  // const title = useSelector((state) => state.dialog.title);
  // const { title, message, button } = useSelector((state) => state.dialog);
  const isOpen = useSelector((state) => { return (state.dialog.id == id) ? state.dialog.isOpen : false});
  // console.log(state.dialog.isOpen);
  const handleDialogClose = () => {
    dispatch(closeDialog(id));
  };

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
    open={isOpen}
    onClose={handleDialogClose}
    aria-labelledby="dialog-title"
   
    >
    {children}
    </Dialog>
    // <Dialog open={isOpen} onClose={handleDialogClose}>
    //   <DialogTitle>{props.title}</DialogTitle>
    //   <DialogContent>{props.message}</DialogContent>
    //   <DialogActions>
    //     <Button variant="contained" onClick={handleDialogClose} color="primary">{props.button}</Button>
    //   </DialogActions>
    // </Dialog>
  );
}

export default SharedDialog;
