import React from "react";
import Sidebar from '../layouts/sidebar/Sidebar';
import ThemeProvider from '../theme';
import {  bankUrl, contactDetailUrl, deviceSignInUrl, documentUrl, educationUrl, employementUrl, familyDetailUrl, ipAddressUrl, officialDetailUrl, passwordUrl, personalDetailUrl } from "../_constant/urlConstData";
import PersonalDetail from "../pages/Profile/PersonalDetail";
import ContactDetail from "../pages/Profile/ContactDetail";
import DocumentDetail from "../pages/Profile/DocumentDetail";
import BankDetail from "../pages/Profile/BankDetail";
import EmployementDetail from "../pages/Profile/EmployementDetail";
import EducationCertificate from "../pages/Profile/EducationCertificate";
import FamilyDetail from "../pages/Profile/FamilyDetail";
import OfficialDetail from "../pages/Profile/OfficialDetail";
import DeviceSigin from "../pages/Security/DeviceSigin";
import IpAddress from "../pages/Security/IpAddress";
import Password from "../pages/Security/Password";

const windowObject = window;

const generatePage = (component) => (

  <ThemeProvider>

    <Sidebar window={windowObject}>
      {component}
    </Sidebar>
  </ThemeProvider>
);
export function PrivateComponent() {
  let response = [
    { url: personalDetailUrl.list, page: generatePage(<PersonalDetail />) },

    { url: contactDetailUrl.list, page: generatePage(<ContactDetail />) },

    { url: officialDetailUrl.list, page: generatePage(<OfficialDetail />) },

    { url: familyDetailUrl.list, page: generatePage(<FamilyDetail />) },

    { url: educationUrl.list, page: generatePage(<EducationCertificate />) },

    { url: employementUrl.list, page: generatePage(<EmployementDetail />) },

    { url: bankUrl.list, page: generatePage(<BankDetail />) },

    { url: documentUrl.list, page: generatePage(<DocumentDetail />) },

    { url: passwordUrl.list, page: generatePage(<Password />) },

    { url: ipAddressUrl.list, page: generatePage(<IpAddress />) },

    { url: deviceSignInUrl.list, page: generatePage(<DeviceSigin />) },






    // -------Awards----------


    // -------Our People----------




    // -------User Data----------



    // -------Newsletter----------



    // -------Celebration----------



    // -------Setup----------



    // -------Report----------



    // -------Suggestion----------



    // -------News----------




    // -------Helpdesk----------



  ];

  return response;
}
