import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useMemo } from "react";
// import MyContext from "../Mycontext";
import MuiAlert from "@mui/material/Alert";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";

function SlideTransition(props) {
  return <Slide {...props} direction="right" />;
}

export default function TransitionsSnackbar(props) {
  // const methodsAndState = React.useContext(MyContext);
  const snakebar = useSelector((state) => state.snakebar?.snakebar)

  const [state, setState] = React.useState({
    open: false,
    Transition: Fade,
  });

  const handleClick = (Transition) => {
    setState({
      open: true,
      Transition,
    });
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  useMemo(() => {
    if (snakebar.dataLoad === true)
      handleClick(SlideTransition);
  }, [snakebar]);

  return (
    <div>
      <Snackbar
        sx={{ zIndex: "99999" }}
        open={state.open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        key={state.Transition.name}
        autoHideDuration={3000}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        }
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          // severity="info" // Set the severity prop to "info"
          severity={snakebar.severity}
          sx={{ width: "100%" }}
        >
          {snakebar.message ?? 'Something went wrong'}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
