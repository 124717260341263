import { createSlice } from '@reduxjs/toolkit';

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    isOpen: false,
    id: '',
    data: {}
  },
  reducers: {
    openDrawer: (state, actions) => {
      console.log("openDrawer ran1")
      state.isOpen = true;
      state.id = actions.payload?.id ?? actions.payload;
      state.data = actions.payload?.data ?? {};
      console.log(state,"openDrawer ran1")

    },
    closeDrawer: (state, actions) => {
      state.isOpen = false;
      state.id = actions.payload;
      console.log('closeDrawer',state,state.isOpen)
    },
  },
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;

export default drawerSlice.reducer;
