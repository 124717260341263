import React, { useState } from 'react'
import { Box, Button, Typography, Stack, List, ListItem, ListItemButton, ListItemText, Link } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import { Add, ArrowForward,  Edit } from '@mui/icons-material';
import AddDocumentDrawer from '../../components/AdminDrawers/AddDocumentDrawer';
import SharedDrawer from '../../features/drawers/Drawer';
import { openDrawer } from '../../features/drawers/drawerSlice';
import SharedDialog from '../../features/dialogBox/dialogBox';
import { openDialog, closeDialog } from '../../features/dialogBox/dialogBoxSlice';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DocumentDetail = ({ readOnly }) => {
    const userDocumentDetails = useSelector((state) => state?.user?.userListName?.documents);
    const [hoveredItems, setHoveredItems] = useState(Array(userDocumentDetails?.length).fill(false));
    const [curerentItemToDelete, setCurerentItemToDelete] = useState({});
    const [currentEditDocumentIndex, setCurrentEditDocumentIndex] = useState();

    const dispatch = useDispatch();

    const openDocumentDrawer = () => {
        dispatch(openDrawer('document'));
    }
    const openEditDocumentDrawer = (data, index) => {
        dispatch(openDrawer({id: 'document', data}));
        setCurrentEditDocumentIndex(index);
    }
    const handleListItemMouseLeave = (index) => {
        const newHoveredItems = [...hoveredItems];
        newHoveredItems[index] = false;
        setHoveredItems(newHoveredItems);
    };
    const handleListItemMouseEnter = (index) => {
        const newHoveredItems = [...hoveredItems];
        newHoveredItems[index] = true;
        setHoveredItems(newHoveredItems);
    };

    const openDeleteDialog = (type, id, index) => {
        setCurerentItemToDelete({ type, id, index });
        dispatch(openDialog('deleteDialog'));
    }

    const deleteHandler = () => {
        if (!curerentItemToDelete.type) {
            return;
        }
        switch (curerentItemToDelete.type) {
            case 'userDocumentDetails': console.log("Look here", { userDocumentDetails, curerentItemToDelete });
                dispatch(closeDialog('deleteDialog'));
                break;
        }
    }
    return (
        <Box>
            <Box sx={{ minHeight: '55rem' }}>
                <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ py: '1rem' }}>
                        <Typography variant="h4" fontWeight="var(--font-weight-5)">Documents</Typography>
                    </Box>
                </Stack>
                <Box>
                    <Card sx={{ mb: 3 }}>
                        <CardContent >
                            <List >
                                {userDocumentDetails?.map((data, index) =>
                                    <ListItem
                                        onMouseEnter={() => handleListItemMouseEnter(index)}
                                        onMouseLeave={() => handleListItemMouseLeave(index)}
                                    >
                                        <ListItemButton sx={{ '&:hover': { backgroundColor: '#DBEAFE', borderRadius: 2 } }}>
                                            <ListItemText>
                                                <Typography variant='body1' fontWeight="var(--font-weight-5)">{data.type}</Typography>
                                                <Link sx={{ textDecoration: "none" }} href={data.Image} target="_blank"> <Typography variant='caption' sx={{ pt: 1 }} fontWeight="var(--font-weight-5)">VIEW UPLOAD DOCUMENT <ArrowForward sx={{ height: 12, width: 20 }} /></Typography></Link><br />
                                            </ListItemText>
                                            {/* {hoveredItems[index] && (
                                                <Box alignSelf={"center"}>
                                                    <Button ><Edit   onClick={() => {openEditDocumentDrawer(data, index)}}/></Button>
                                                    <Button onClick={() => openDeleteDialog('userDocumentDetails', data.id, index )}  ><Delete /></Button>
                                                </Box>)} */}
                                        </ListItemButton>
                                    </ListItem>)}
                            </List>
                            <Box textAlign={"center"}>
                                <Button startIcon={<Add />} onClick={openDocumentDrawer}> ADD DOCUMENT</Button>
                            </Box>
                        </CardContent>
                    </Card>
                    <SharedDrawer id="document">
                        <AddDocumentDrawer parentId='document' currentEditDocumentIndex={currentEditDocumentIndex} />
                    </SharedDrawer>
                    <SharedDialog id="deleteDialog">
                        <Box pb="1rem">
                            <DialogTitle id="alert-dialog-title">
                                {"Delete"}
                            </DialogTitle>
                            <DialogContent sx={{ padding: '16px 24px' }}>
                                <DialogContentText sx={{ color: "#000000" }} id="alert-dialog-description">
                                    This will be deleted forever and you won't be able to restore it.
                                    {/* {methodsAndState.dynamicDeleteMessage} */}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ padding: '16px 24px' }}>
                                <Button onClick={() => dispatch(closeDialog('deleteDialog'))}>
                                    CANCEL
                                </Button>
                                <Button onClick={deleteHandler} color='primary' variant="contained" >YES, DELETE</Button>
                            </DialogActions>
                        </Box>
                    </SharedDialog>
                </Box>
            </Box>
        </Box>
    )
}

export default DocumentDetail